import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import InnerHTML from "../../components/InnerHTML";
import { t } from "../../intl/getTranslation";
import { Heading, Paragraph, SubHeading } from "./PreSection";

const SecondSection: FC = () => {
  const intl = useIntl();

  return (
    <>
      <Heading>{t("privacyPolicy.section_2.header", intl)}</Heading>
      <Paragraph>
        <InnerHTML content={t("privacyPolicy.section_2.paragraph_1", intl)} />
      </Paragraph>
      <SubHeading>
        <InnerHTML content={t("privacyPolicy.section_2.sub_header_1", intl)} />
      </SubHeading>
      <Paragraph>
        <InnerHTML content={t("privacyPolicy.section_2.paragraph_2", intl)} />
      </Paragraph>
      <Paragraph>
        <InnerHTML content={t("privacyPolicy.section_2.paragraph_3", intl)} />
      </Paragraph>
      <SubHeading>{t("privacyPolicy.section_2.sub_header_2", intl)}</SubHeading>
      <Paragraph>
        <InnerHTML content={t("privacyPolicy.section_2.paragraph_4", intl)} />
      </Paragraph>
      <Paragraph>
        <InnerHTML content={t("privacyPolicy.section_2.paragraph_5", intl)} />
      </Paragraph>
      <SubHeading>{t("privacyPolicy.section_2.sub_header_3", intl)}</SubHeading>
    </>
  );
};

export default SecondSection;
