import React, { FC } from "react";

import Container from "../components/Container";
import Layout from "../components/Layout";
import Spacer from "../components/Spacer";
import FifthSection from "../partials/PrivacyPolicy/FifthSection";
import FirstSection from "../partials/PrivacyPolicy/FirstSection";
import FourthSection from "../partials/PrivacyPolicy/FourthSection";
import PreSection from "../partials/PrivacyPolicy/PreSection";
import SecondSection from "../partials/PrivacyPolicy/SecondSection";
import SixthSection from "../partials/PrivacyPolicy/SixthSection";
import ThirdSection from "../partials/PrivacyPolicy/ThirdSection";

const PrivacyPolicy: FC = () => {
  return (
    <Layout
      helmetKey="privacyPolicy"
      emptyMenu={true}
      menuIconType="/"
      render={(): React.ReactChild => (
        <Container>
          <Spacer size="extraLarge" />
          <PreSection />
          <FirstSection />
          <SecondSection />
          <ThirdSection />
          <FourthSection />
          <FifthSection />
          <SixthSection />
          <Spacer size="huge" />
        </Container>
      )}
    />
  );
};

export default PrivacyPolicy;
