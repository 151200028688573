import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import InnerHTML from "../../components/InnerHTML";
import { t } from "../../intl/getTranslation";
import { Heading, NumberedList, Paragraph } from "./PreSection";

const FirstSection: FC = () => {
  const intl = useIntl();

  return (
    <>
      <Heading>{t("privacyPolicy.section_1.header", intl)}</Heading>
      <Paragraph>
        <InnerHTML content={t("privacyPolicy.section_1.paragraph_1", intl)} />
      </Paragraph>
      <NumberedList>
        <li>
          <InnerHTML content={t("privacyPolicy.section_1.list_1", intl)} />
        </li>
        <li>
          <InnerHTML content={t("privacyPolicy.section_1.list_2", intl)} />
        </li>
        <li>
          <InnerHTML content={t("privacyPolicy.section_1.list_3", intl)} />
        </li>
        <li>
          <InnerHTML content={t("privacyPolicy.section_1.list_4", intl)} />
        </li>
      </NumberedList>
    </>
  );
};

export default FirstSection;
