import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import InnerHTML from "../../components/InnerHTML";
import { t } from "../../intl/getTranslation";
import { Heading, Paragraph } from "./PreSection";

const ThirdSection: FC = () => {
  const intl = useIntl();

  return (
    <>
      <Heading>{t("privacyPolicy.section_3.header", intl)}</Heading>
      <Paragraph>
        <InnerHTML content={t("privacyPolicy.section_3.paragraph_1", intl)} />
      </Paragraph>
      <Paragraph>
        <InnerHTML content={t("privacyPolicy.section_3.paragraph_2", intl)} />
      </Paragraph>
      <Paragraph>
        <InnerHTML content={t("privacyPolicy.section_3.paragraph_3", intl)} />
      </Paragraph>
    </>
  );
};

export default ThirdSection;
