import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import InnerHTML from "../../components/InnerHTML";
import Spacer from "../../components/Spacer";
import { t } from "../../intl/getTranslation";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";

const CenterWrapper = styled.span`
  text-align: center;
`;

const MainHeading = styled.h1`
  font-size: ${rem(30)};
  font-weight: 700;
  font-family: "Overpass Mono";
`;

export const Heading = styled.h1`
  font-size: ${rem(22)};
  font-weight: 700;
  margin-top: ${rem(40)};
  font-family: "Overpass Mono";
`;

export const SubHeading = styled(Heading)`
  font-size: ${rem(18)};
`;

export const Note = styled.span`
  font-size: ${rem(16)};
  display: block;
`;

export const Paragraph = styled.p`
  color: ${({ theme }): string => theme.colors.lightGray};
  font-weight: 300;
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  text-align: justify;
  ${({ theme }): string[] => [theme.media.maxM]} {
    font-size: ${rem(16)};
  }
  b {
    color: ${({ theme }): string => theme.colors.white};
  }
`;

export const List = styled.ul`
  color: ${({ theme }): string => theme.colors.lightGray};
  text-align: justify;
  font-size: ${rem(14)};
  ${({ theme }): string[] => [theme.media.maxM]} {
    font-size: ${rem(16)};
  }
  li {
    margin-bottom: ${rem(10)};
    b {
      color: ${({ theme }): string => theme.colors.white};
    }
  }
`;

export const NumberedList = styled(List)`
  list-style-type: lower-latin;
`;

const PreSection: FC = () => {
  const intl = useIntl();

  return (
    <>
      <CenterWrapper>
        <MainHeading>{t("privacyPolicy.header", intl)}</MainHeading>
        <Note>{t("privacyPolicy.note", intl)}</Note>
      </CenterWrapper>
      <Spacer size="medium" />
      <Paragraph>
        <InnerHTML content={t("privacyPolicy.paragraph_1", intl)} />
      </Paragraph>
      <Paragraph>
        <InnerHTML content={t("privacyPolicy.paragraph_2", intl)} />
      </Paragraph>
    </>
  );
};

export default PreSection;
