import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import InnerHTML from "../../components/InnerHTML";
import { t } from "../../intl/getTranslation";
import { Heading, List, Paragraph, SubHeading } from "./PreSection";

const FifthSection: FC = () => {
  const intl = useIntl();

  return (
    <>
      <Heading>{t("privacyPolicy.section_5.header", intl)}</Heading>
      <Paragraph>{t("privacyPolicy.section_5.paragraph_1", intl)}</Paragraph>
      <SubHeading>{t("privacyPolicy.section_5.sub_header_1", intl)}</SubHeading>
      <Paragraph>{t("privacyPolicy.section_5.paragraph_2", intl)}</Paragraph>
      <SubHeading>{t("privacyPolicy.section_5.sub_header_2", intl)}</SubHeading>
      <Paragraph>{t("privacyPolicy.section_5.paragraph_3", intl)}</Paragraph>
      <SubHeading>{t("privacyPolicy.section_5.sub_header_3", intl)}</SubHeading>
      <Paragraph>{t("privacyPolicy.section_5.paragraph_4", intl)}</Paragraph>
      <List>
        <li>
          <InnerHTML content={t("privacyPolicy.section_5.list_1", intl)} />
        </li>
        <li>
          <InnerHTML content={t("privacyPolicy.section_5.list_2", intl)} />
        </li>
        <li>
          <InnerHTML content={t("privacyPolicy.section_5.list_3", intl)} />
        </li>
        <li>
          <InnerHTML content={t("privacyPolicy.section_5.list_4", intl)} />
        </li>
      </List>
      <Paragraph>{t("privacyPolicy.section_5.paragraph_5", intl)}</Paragraph>
      <List>
        <li>
          <InnerHTML content={t("privacyPolicy.section_5.list_5", intl)} />
        </li>
        <li>
          <InnerHTML content={t("privacyPolicy.section_5.list_6", intl)} />
        </li>
        <li>
          <InnerHTML content={t("privacyPolicy.section_5.list_7", intl)} />
        </li>
      </List>
      <SubHeading>{t("privacyPolicy.section_5.sub_header_4", intl)}</SubHeading>
      <Paragraph>{t("privacyPolicy.section_5.paragraph_6", intl)}</Paragraph>
      <List>
        <li>
          <InnerHTML content={t("privacyPolicy.section_5.list_8", intl)} />
        </li>
        <li>
          <InnerHTML content={t("privacyPolicy.section_5.list_9", intl)} />
        </li>
        <li>
          <InnerHTML content={t("privacyPolicy.section_5.list_10", intl)} />
        </li>
        <li>
          <InnerHTML content={t("privacyPolicy.section_5.list_11", intl)} />
        </li>
      </List>
      <SubHeading>{t("privacyPolicy.section_5.sub_header_5", intl)}</SubHeading>
      <Paragraph>{t("privacyPolicy.section_5.paragraph_7", intl)}</Paragraph>
      <SubHeading>{t("privacyPolicy.section_5.sub_header_6", intl)}</SubHeading>
      <Paragraph>{t("privacyPolicy.section_5.paragraph_8", intl)}</Paragraph>
      <SubHeading>{t("privacyPolicy.section_5.sub_header_7", intl)}</SubHeading>
      <Paragraph>{t("privacyPolicy.section_5.paragraph_9", intl)}</Paragraph>
    </>
  );
};

export default FifthSection;
