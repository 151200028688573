import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import { t } from "../../intl/getTranslation";
import { Paragraph } from "./PreSection";

const SixthSection: FC = () => {
  const intl = useIntl();

  return (
    <>
      <Paragraph>{t("privacyPolicy.section_6.paragraph_1", intl)}</Paragraph>
      <Paragraph>{t("privacyPolicy.section_6.paragraph_2", intl)}</Paragraph>
      <Paragraph>{t("privacyPolicy.section_6.paragraph_3", intl)}</Paragraph>
    </>
  );
};

export default SixthSection;
